import React,{useState, useEffect} from "react";
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faDollarSign,
  faEye,
  faHeart,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import FeedContextMenu from "./FeedContextMenu/FeedContextMenu";
import { connect } from "react-redux";
import { photosPost, postDelete } from "../redux/actions/postActions";
import { getUserById, getUserByName } from "../redux/actions/userActions";
import { withRouter } from "react-router-dom";
import StripePayment from "../containers/StripeTest/StripePayment";
import {Modal,Button} from 'react-bootstrap';
import StripeCustomer from "../containers/StripeCustomer/StripeCustomer";
import {payExhibit} from '../redux/actions/postActions';
import FontAwesome from "react-fontawesome";

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import "swiper/swiper-bundle.min.css";
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/modules/lazy/lazy.min.css"
import SwiperCore, { Pagination, Lazy } from "swiper";

SwiperCore.use([Pagination, Lazy]);

function FeedItem(props) {

  let [showDonate, setShowDonate ] = useState(false);
  let [donate,setDonate] = useState(0);
  let [showPayment, setShowPayment] = useState(false);

  useEffect(() => {
     let el = document.querySelector('.image-swipe-gallery-' + props.feed.id);
     if(el){
        el.addEventListener('dblclick',  () => {
          props.like(props.feed, props.index);
        });
     }

  },[]);

  let paidStatus = (props.user.id !== props.feed.user.id &&
    props.feed.paid === 1 &&
    !props.feed.paidExhibit);

  let hidePaymentExhibit = () => {
    setShowPayment(false);
    window.location.reload();
  }

  return (
    <React.Fragment>
      {!( props.feed.mature === 1 && props.user.is_adult_show === 0) && !(props.feed.category !== null && props.feed.category.mature === 1 && props.user.is_adult_show === 0) ? 
        
        <div className="slider-item-list profile-account-holder  bg-white post-item-list">
          <div className={"post-header p-2 d-flex justify-content-between"}>
            <div className={"d-flex align-items-center"}>
              <img
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.history.push(
                    `/my-profile/highlights/${props.feed.user.name}`
                  );
                }}
                src={props.settings.mainUrl + "/storage/" + props.feed.user.avatar}
                className={"rounded-circle mr-3"}
                width={30}
              />
              <div className={"position-relative post-author  d-flex"}>
                <div
                  className={"font-weight-600"}
                  onClick={() => {
                    props.history.push(
                      `/my-profile/highlights/${props.feed.user.name}`
                    );
                  }}
                >
                  {" "}
                  {props.feed.user.full_name}
                </div>
                <div className={"author-badge"} style={{}}>
                  {
                    props.feed.user.badge !== ''? <img src={require(`../assets/img/${props.feed.user.badge}.png`)} width={15} />   : null
                  }

                </div>
              </div>
            </div>
            <div>
              {" "}
              {props.feed.category !== null &&
              Object.keys(props.feed.category).length > 0 ? (
                <button className={"btn btn-primary"}>
                  {props.feed.category.name}
                </button>
              ) : null}
            </div>
          </div>

          {
            1 ?
              <div className={"post-image-block"}>
                <Swiper 
                direction={"vertical"}
                pagination={{
                  clickable: true
                }}
                // loop={true}
                className="mySwiper"
                
                
                >
                  <SwiperSlide>
                {props.feed.type === 0 ? (
                  <React.Fragment>
                    {props.feed.photo.length > 0 ? (
                      <React.Fragment>
                        <ImageGallery
                          items={props.photosPost(props.feed,!paidStatus)}
                          showThumbnails={false}
                          showFullscreenButton={false}
                          showPlayButton={false}
                          additionalClass={`image-swipe-gallery-${props.feed.id}`}
                        />
                    </React.Fragment>
                    ) : (
                      <img
                        onDoubleClick={() => {
                          props.like(props.feed, props.index);
                        }}
                        data-src={props.settings.mainUrl + "/storage/" + props.feed.image}
                        className={"img-full swiper-lazy"}
                      />

                      
                    )}
                  </React.Fragment>
                  
                ) : null}

                {props.feed.type === 1 ? (
                  <React.Fragment>
                    {!paidStatus ? <video
                      className={"img-full"}
                      poster={props.feed.image === null || props.feed.image === ""
                        ? require("./../assets/img/video-process.png")
                        : props.settings.mainUrl +
                        "/storage" +
                        props.feed.image}
                      controls={true}
                      src={props.settings.mainUrl + "/storage" + props.feed.video}
                    /> :
                    <img  data-src={props.feed.image === null || props.feed.image === ""
                      ? require("./../assets/img/video-process.png")
                      : props.settings.mainUrl +
                      "/storage" +
                      props.feed.image}
                          className={"img-full swiper-lazy"} />
                          
                    }

                  </React.Fragment>


                ) : null}
                  </SwiperSlide>
                  <SwiperSlide>
                {props.feed.type === 0 ? (
                  <React.Fragment>
                    {props.feed.photo.length > 0 ? (
                      <React.Fragment>
                        <ImageGallery
                          items={props.photosPost(props.feed,!paidStatus)}
                          showThumbnails={false}
                          showFullscreenButton={false}
                          showPlayButton={false}
                          additionalClass={`image-swipe-gallery-${props.feed.id}`}
                        />
                    </React.Fragment>
                    ) : (
                      <img
                        onDoubleClick={() => {
                          props.like(props.feed, props.index);
                        }}
                        data-src={props.settings.mainUrl + "/storage/" + props.feed.image}
                        className={"img-full swiper-lazy"}
                      />

                      
                    )}
                  </React.Fragment>
                  
                ) : null}

                {props.feed.type === 1 ? (
                  <React.Fragment>
                    {!paidStatus ? <video
                      className={"img-full"}
                      poster={props.feed.image === null || props.feed.image === ""
                        ? require("./../assets/img/video-process.png")
                        : props.settings.mainUrl +
                        "/storage" +
                        props.feed.image}
                      controls={true}
                      src={props.settings.mainUrl + "/storage" + props.feed.video}
                    /> :
                    <img  data-src={props.feed.image === null || props.feed.image === ""
                      ? require("./../assets/img/video-process.png")
                      : props.settings.mainUrl +
                      "/storage" +
                      props.feed.image}
                          className={"img-full swiper-lazy"} />
                          
                    }

                  </React.Fragment>


                ) : null}
                  </SwiperSlide>
                </Swiper>
              </div>
              : null
          }


          <div className={"p-3"}>
            <div className={"post-statuses d-flex justify-content-between mb-3"}>
              <div>
                <a
                  href={""}
                  className={[
                    "mr-4 ",
                    props.feed.likeStars.indexOf(props.user.id) !== -1
                      ? "active"
                      : "",
                  ].join(" ")}
                  onClick={(e) => {
                    e.preventDefault();
                    props.likeStar(props.feed, props.index);
                  }}
                >
                  <FontAwesomeIcon icon={faStar} /> {props.feed.likeStars.length}
                </a>
                <a
                  href={""}
                  className={[
                    "mr-4 ",
                    props.feed.likes.indexOf(props.user.id) !== -1 ? "active" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    e.preventDefault();
                    props.like(props.feed, props.index);
                  }}
                >
                  <FontAwesomeIcon icon={faHeart} /> {props.feed.likes.length}
                </a>
                <a href={""} className={"mr-4"}>
                  <FontAwesomeIcon icon={faCommentDots} /> {props.feed.commentCount}
                </a>
                <a href={""} className={"mr-4"} onClick={e => {
                  e.preventDefault();
                  setShowDonate(true)
                }}>
                  <FontAwesomeIcon icon={faDollarSign} /> {props.feed.earning}
                </a>
              </div>
              <div>
                <FeedContextMenu
                  feed={props.feed}
                  user={props.user}
                  postDelete={props.postDelete}
                  deleteFromList={() => {
                    props.deleteFromList(props.index);
                  }}
                />
              </div>
            </div>

            <div className={"post-title mb-3 "}>
              {props.feed.name}
            </div>
            <div className={"post-button-block  mb-3"}>
              {props.user.id !== props.feed.user.id &&
              props.feed.paid === 1 &&
              !props.feed.paidExhibit ?
                (
                  <React.Fragment>
                    {/*<StripePayment type={"exhibit"} data={props.feed} />*/}
                  <button type={'button'} onClick={() => {
                    console.log(props.user.customer_id , props.user.payment_method_id );
                    if(props.user.customer_id === null || props.user.payment_method_id === null){
                      setShowPayment(true)
                    }
                    else {
                      if(window.confirm('Do you confirm payment?')){
                        props.payExhibit(props.feed);
                      }

                    }


                  }} className={'btn btn-bordered-dark btn-block font-weight-400'} style={{width:'100%'}}><FontAwesome name={'lock'}/> Unlock full post for ${props.feed.price}</button>
                  </React.Fragment>

                ) : <button
                  type={"button"}
                  className={"btn btn-bordered-dark btn-block font-weight-400"}
                  onClick={() => {
                    props.history.push(props.feedLink);
                  }}
                >
                  <FontAwesomeIcon icon={faEye} /> View full post
                </button>}

            </div>
          </div>

          <Modal  show={showDonate} onHide={() => {setShowDonate(false)}}>
            <Modal.Header closeButton>
              <Modal.Title>Request Donate</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>
                Sum donate
              </div>
              <input value={donate} step={0.01} type={'number'} onChange={e => {setDonate((e.target.value))}} className={'form-control bordered'}/>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => {
                setShowDonate(false)

              }}>Close</Button>
              <StripePayment type={'donate_post'} data={{...props.feed,donate}}/>
            </Modal.Footer>
          </Modal>


          <Modal show={showPayment} onHide={() => setShowPayment(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                Payment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <StripeCustomer
                  data={{
                    type:'exhibit',
                    data:props.feed
                  }}
                  hidePayment={() => {
                    hidePaymentExhibit();
                  }}
              />
            </Modal.Body>

          </Modal>

        </div>

       : null}
    </React.Fragment>

  );
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getUserById: (id_user) => {
      return dispatch(getUserById(id_user));
    },
    getUserByName: (name) => {
      return dispatch(getUserByName(name));
    },
    postDelete: (id) => {
      return dispatch(postDelete(id));
    },
    photosPost: (feed,status) => {
      return dispatch(photosPost(feed,status));
    },
    payExhibit:(post) => {
      return dispatch(payExhibit(post));
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeedItem));
