import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UploadFiles from "../../components/UploadFiles/UploadFiles";
import axios from "axios";
import { createOrder,simplePay,changeStatus } from "../../redux/actions/orderActions";

import { viewService } from "../../redux/actions/serviceActions";
import StripeCustomer from "../StripeCustomer/StripeCustomer";
import {Modal} from "react-bootstrap";

class ServicePlaceOrder extends Component {
  state = {
    formData: {
      description: "",
      fileNames: [],
    },
    service:{},
    isPaymentShow:false,
    order:{}
  };
  componentDidMount() {
    let id = this.props.match.params.service;
    this.props.viewService(id).then((response) => {
      console.log(response);
      this.setState({
        service: { ...response },
      });
    });
  }

  hidePayment = () => {
    this.setState({
      isPaymentShow:false
    })
  }

  showPayment = () => {
    this.setState({
      isPaymentShow:true
    })
  }

  render() {
    console.log(this.state)
    return (
      <React.Fragment>
        <React.Fragment>
          <DesktopNavbar />
          <MobileNavbar >{ Object.keys(this.state.service).length > 0 ? `@${this.state.service.user.name}` : ' '}</MobileNavbar>
          <BaseLayout>
            <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
              <div className={" row sidebar-menu"}>
                <div className={"col-12 "}>
                  <div className={"bg-white profile-account-holder desktop"}>
                    <h2>@{ Object.keys(this.state.service).length > 0 ? this.state.service.user.name : null}</h2>
                    <MyProfileSidebar active={"services"} isOwner={false} userLink={
                      Object.keys(this.state.service).length > 0
                        ? `/${this.state.service.user.name}`
                        : ""
                    } />
                  </div>
                  <div className={"mobile"}>
                    <MyProfileSidebarMobile
                      isOwner={false}
                      userLink={
                        Object.keys(this.state.service).length > 0
                          ? `/${this.state.service.user.name}`
                          : ""
                      }
                      active={{
                        value: "/my-profile/services",
                        label: "Services",
                      }}
                    />
                  </div>
                </div>
              </div>
            </aside>
            <main
              className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
              style={{ padding: "0 8px" }}
            >
              <div className="profile-account-holder  mb-3  bg-white post-item-list service-place-order">
                <div className={"service-place-order-title"}>
                  Thank you for your order
                </div>
                <div className={"upload-block"}>
                  <span className={"d-block"}> Upload any relevant files:</span>

                  <form method="post" enctype="multipart/form-data">
                    <input
                      className={"btn btn-primary"}
                      type={"file"}
                      name="file"
                      id={"file-upload-multi"}
                      multiple={true}
                      onChange={(event) => {
                        let data = new FormData();

                        data.append("api_token", this.props.user.api_token);
                        for (var i = 0; i < event.target.files.length; i++) {
                          data.append(
                            "fileNames[" + i + "]",
                            event.target.files[i]
                          );
                        }

                        axios
                          .post(
                            this.props.settings.mainUrl +
                              "/api/upload-files-multi",
                            data,
                            {
                              headers: {
                                "content-type": "multipart/form-data",
                              },
                            }
                          )
                          .then((response) => {

                            this.setState(
                              {
                                formData: {
                                  ...this.state.formData,
                                  fileNames: [...response],
                                },
                              },
                              () => {
                                alert("Files loaded success");
                              }
                            );
                          })
                          .catch((error) => {
                            console.log(error);
                            alert("File loaded error");
                          });
                      }}
                    />
                  </form>
                </div>
                <div className={"description-block"}>
                  <span className={"d-block"}>
                    Tell me about your project and ypur vision:
                  </span>
                  <textarea
                    className={"form-control bordered"}
                    rows={8}
                    value={this.state.formData.description}
                    onChange={(e) => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          description: e.target.value,
                        },
                      });
                    }}
                  />
                </div>

                <button
                  className={"btn btn-primary"}
                  style={{ width: "100%" }}
                  onClick={() => {
                    this.props
                      .createOrder(
                        this.state.formData,
                        this.props.match.params.service,
                        this.props.user.id
                      )
                      .then((response) => {

                        this.setState({
                          order:{...response}
                        },() => {
                          if(this.props.user.customer_id !== null || this.props.user.payment_method_id !== null){
                            if(window.confirm('Do you confirm payment?')){
                              this.props.simplePay(this.state.order).then(response => {
                                  this.props.changeStatus(this.state.order.id, 9).then(response => {
                                    window.location.href = '/';
                                  })
                              });
                            }
                          }
                          else {
                            this.showPayment();
                          }
                        })

                        //this.props.history.push("/service-order-result");
                      });


                  }}
                >
                  Submit
                </button>
                {Object.keys(this.state.order).length > 0
                ? <Modal show={this.state.isPaymentShow} onHide={() => this.hidePayment()}>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Payment
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <StripeCustomer
                            data={{
                              type:'service',
                              data:{
                                ...this.state.order
                              }
                            }}
                            hidePayment={() => {

                              this.hidePayment();
                            }}
                        />
                      </Modal.Body>
                    </Modal>
                : null}

              </div>
            </main>
            <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
              <MyProfileUserBlock user={this.props.user} />
            </aside>
          </BaseLayout>
          <Tabbar />
        </React.Fragment>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    viewService: (id) => {
      return dispatch(viewService(id));
    },
    simplePay:(id_service) => {
      return dispatch(simplePay(id_service));
    },
    createOrder:(formData,service,buyer) => {
      return dispatch(createOrder(formData,service, buyer))
    },
    changeStatus:(id_order, status) => {
      return dispatch(changeStatus(id_order,status))
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ServicePlaceOrder));
