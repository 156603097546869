import React, { Component } from "react";
import BaseLayout from "../../layouts/BaseLayout";
import DesktopSidebar from "../AccountSettings/DesktopSidebar";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { listServices } from "../../redux/actions/serviceActions";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import { getUserByName } from "../../redux/actions/userActions";
import { withRouter } from "react-router-dom";
import NotFoundBlock from "../NotFound/NotFoundBlock";

class MyProfileServices extends Component {
  state = {
    user: {},
    list: [],
  };
  componentDidMount() {
    if (!this.props.match.params.user) {
      this.setState(
        {
          user: { ...this.props.user },
        },
        () => {
          this.checkDeactivated();
          this.setServices(this.state.user.id);
        }
      );
    } else {
      this.props
        .getUserByName(this.props.match.params.user)
        .then((response) => {
          this.setState(
            {
              user: { ...response.data },
            },
            () => {
              this.checkDeactivated();
              this.setServices(this.state.user.id);
            }
          );
        });
    }
  }

  setServices = (id_user) => {
    this.props.listServices(id_user).then((response) => {
      this.setState({
        list: [...response],
      });
    });
  };

  checkDeactivated = () => {
    if(this.state.user.status === -1){
      this.props.history.push('/my-profile/about/' + this.state.user.name);
    }
  };

  render() {
    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar >{this.state.user.name !== this.props.user.name ? `@${this.state.user.name}`  : ' '}</MobileNavbar>

        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile order-1 ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>@{this.state.user.name}</h2>
                  <MyProfileSidebar
                    isOwner={this.state.user.name === this.props.user.name}
                    active={"services"}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  />
                </div>
                <div className={"mobile"}>
                  <MyProfileSidebarMobile
                    isOwner={this.state.user.name === this.props.user.name}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                    active={{
                      value: "/my-profile/services",
                      label: this.state.user.name === this.props.user.name ? "My Services" : 'Services',
                    }}
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list order-2"
            style={{ padding: "0 8px" }}
          >
            <div className={"row"}>
              {this.state.user.id === this.props.user.id ? (
                <div className={"col-12 mb-2"}>
                  <a
                    href={""}
                    className="btn btn-primary btn-block card-button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/create-service`);
                    }}
                  >
                    Create Service
                  </a>
                </div>
              ) : null}

              {this.state.list.length === 0 ?<div className={'col-12'}> <NotFoundBlock/> </div> : null}
              {this.state.list.map((service, index) => {
                return (
                  <div className={"col-lg-4"} key={`service_${index}`}>
                    <div className="box shadow-sm border rounded bg-white mb-4">
                      <div className="card  card-recommendation-user online ">
                        <img
                          className="card-img-top"
                          src={
                            this.props.settings.mainUrl +
                            "/storage/" +
                            service.description_image
                          }
                          alt="Card image cap"
                        />
                        <div className="avatar-block">
                          <div className="img-block rounded-circle">
                            <img
                              className="rounded-circle"
                              src={
                                this.props.settings.mainUrl +
                                "/storage/" +
                                service.user.avatar
                              }
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.props.history.push(
                                  `/my-profile/highlights/${service.user.name}`
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="card-body">
                            <div className="card-title position-relative d-inline-block">
                              <h5
                                className="title font-weight-600"
                                style={{ fontSize: 13 }}
                              >
                                {service.user.full_name}
                              </h5>
                              {
                                service.user.badge !== ''? <img className="verify position-absolute" src={require(`../../assets/img/${service.user.badge}.png`)} width={15} />   : null
                              }

                            </div>
                            <h6
                              className="text-center font-weight-600 mb-3"
                              style={{ fontSize: 12 }}
                            >
                              {service.name}
                            </h6>
                            <p className="card-text">{service.description}</p>
                          </div>
                          {service.user.id === this.props.user.id ? (
                            <a
                              href={""}
                              className="btn btn-primary btn-block card-button"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                  `/edit-service/${service.id}`
                                );
                              }}
                            >
                              Edit Service
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="btn btn-primary btn-block card-button"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                  `/service/${service.id}`
                                );
                              }}
                            >
                              Order for ${service.price}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12 order-0">
            <MyProfileUserBlock user={this.state.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getUserByName: (name) => {
      return dispatch(getUserByName(name));
    },
    listServices: (id_user) => {
      return dispatch(listServices(id_user));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyProfileServices));
