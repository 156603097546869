import { CREATE_POST } from "../actions/actionTypes";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";

export function createPost(formData) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(`${mainUrl}/api/post`, {
      ...formData,
      user_id: getState().user.user.id,
      api_token: getState().user.user.api_token,
    });
    return response;
  };
}

export function fetchMyFeed(id_user) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(`${mainUrl}/api/feed/${id_user}`, {
      params: {
        api_token: getState().user.user.api_token,
        id_user: getState().user.user.id,
      },
    });
    return response;
  };
}

export function fetchHomeFeed(id_user, id_category) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/homeFeed/${id_user}/category/${id_category}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}

export function fetchSimpleFeed(id_post) {
  return async (dispatch, getState) => {
    let id_user = getState().user.user.id;
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/post/${id_post}/user/${id_user}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}

export function like(id_post, id_user) {
  console.log(id_post);
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(
      `${mainUrl}/api/post/like/${id_post}/${id_user}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response;
  };
}

export function unlike(id_post, id_user) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.patch(
      `${mainUrl}/api/post/unlike/${id_post}/${id_user}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response;
  };
}

export function likeStar(id_post, id_user) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(
      `${mainUrl}/api/post/star-like/${id_post}/${id_user}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response;
  };
}

export function createComment(comment, id_post, id_user) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(
      `${mainUrl}/api/post/comment/${comment}/user/${id_user}/post/${id_post}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response;
  };
}

export function postComments(id_post) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(`${mainUrl}/api/post/comment/${id_post}`, {
      params: {
        api_token: getState().user.user.api_token,
      },
    });
    return response;
  };
}

export function postCommentsOffset(id_post, offset) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/post/comment/${id_post}/offset/${offset}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}

export function postDelete(id) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.delete(mainUrl + `/api/post/${id}`, {
      params: {
        api_token: getState().user.user.api_token,
      },
    });
    return response;
  };
}

export function editPost(formData, id) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.patch(mainUrl + `/api/post/${id}`, {
      formData: formData,
      api_token: getState().user.user.api_token,
    });
    return response;
  };
}

export function deleteImage(id) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.delete(mainUrl + `/api/post-image/${id}`, {
      params: {
        api_token: getState().user.user.api_token,
      },
    });
    return response;
  };
}

export function isVideoFile(fileName) {
  return (dispatch, getState) => {
    let allowedExtensions = [
      "m4v",
      "mp4",
      "ogm",
      "wmv",
      "mpg",
      "webm",
      "ogv",
      "mov",
      "asx",
      "mpeg",
      "avi",
    ];
    let extension = fileName.split(".").pop();
    return allowedExtensions.indexOf(extension) !== -1;
  };
}

export function fetchMyExhibits(id_user) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/post/my-exhibits/${id_user}`,
      {
        params: {
          api_token: getState().user.user.api_token,
          id_user: getState().user.user.id,
        },
      }
    );
    return response;
  };
}

export function setHighlights(id_post) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.patch(
      `${mainUrl}/api/post/highlight/${id_post}`,
      {
        api_token: getState().user.user.api_token,
      }
    );

    return response;
  };
}

export function trendingFeed(id_category) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(
      `${mainUrl}/api/post/trending-feed/${id_category}/user/${id_user}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}
export function trendingExhibits() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(
      `${mainUrl}/api/post/trending-exhibits/${id_user}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}

export function photosPost(feed,status) {
  return (dispatch, getState) => {
    let images = [];
    let mainUrl = getState().settings.mainUrl;
    images.push({
      original: mainUrl + "/storage/" + feed.image,
      thumbnail: mainUrl + "/storage/" + feed.image,
    });
    if(status){
      for (var i = 0; i < feed.photo.length; i++) {
        images.push({
          original: mainUrl + "/storage/" + feed.photo[i].src,
          thumbnail: mainUrl + "/storage/" + feed.photo[i].src,
        });
      }

    }
    console.log(images,status)
    return images;
  };
}

export function myHighlights(id_user) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;

    let response = await axios.get(
      `${mainUrl}/api/post/my-highlights/${id_user}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}


export function numbersVideoPostLast24Hours() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(
      `${mainUrl}/api/post/numbersVideoPostLast24Hours/${id_user}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  }
}

export function payExhibit(post) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let user = getState().user.user;
    const stripePromise = loadStripe("pk_test_Du0fzY5XkR7m8qkwrWanhqpC00TMxFfEet");
    stripePromise.then(stripeResponse => {
      axios.post(`${mainUrl}/api/stripe/payment-intent-repeat/${post.id}/customer/${user.customer_id}/payment-method/${user.payment_method_id}/user/${user.id}`,{
        api_token: getState().user.user.api_token,
      }).then(response => {
        window.location.reload();
      });

    })

  }
}