import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faStar } from "@fortawesome/free-solid-svg-icons";
import {
  getFollows,
  subscribe,
  unsubscribe,
} from "../../redux/actions/subscriptionsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userNewData, userViewToken } from "../../redux/actions/userActions";
import {shortFormNumbers} from '../../redux/actions/functionActions'


class MyProfileUserBlock extends Component {
  componentDidMount() {}

  render() {
    
    return (
      <React.Fragment>
        <div className=" profile-account-holder bg-white ">
          <div className="box mb-3  rounded bg-white profile-box  online">
            <div className="py-4 px-3 d-flex">
              <div className="profile-image-box ">
                <img
                  src={
                    this.props.settings.mainUrl +
                    "/storage/" +
                    this.props.user.avatar
                  }
                  className="img-fluid mt-2 rounded-circle"
                  alt="Responsive image"
                  style={{ width: 150 }}
                />
              </div>
              <div className="profile-main-data-container">
                <div className="name-block mb-2 text-center">
                  <h5
                    className=" text-dark mb-1 mt-4 position-relative font-weight-600"
                    onClick={() => {
                      this.props.history.push("/my-profile/highlights");
                    }}
                  >
                    {`${this.props.user.full_name}`}
                    {
                     Object.keys(this.props.user).length > 0 && this.props.user.badge !== ''? <img style={{ top: "-5px", cursor: "pointer" }} className="position-absolute" src={require(`../../assets/img/${this.props.user.badge}.png`)} width={20} />   : null
                    }

                  </h5>
                  <div>
                    <span className="font-weight-600"> {this.props.user.title}</span>
                  </div>
                </div>
                <div className="indicators-profile row text-center justify-content-center">
                  <div className="col-auto ">
                    <h4 className="mb-1">
                      {this.props.user.follows === null
                        ? 0
                        : shortFormNumbers(this.props.user.follows)}
                    </h4>
                    <div className="font-weight-600 indicator-title">
                      Followers
                    </div>
                  </div>
                  <div className="col-auto ">
                    <h4 className="mb-1">{shortFormNumbers(this.props.user.experience) }</h4>
                    <div className="font-weight-600 indicator-title">
                      Experience
                    </div>
                  </div>
                  <div className="col-auto  ">
                    <h4 className="mb-1">
                      <FontAwesomeIcon
                        style={{ fontSize: 15, color: "#fabd05" }}
                        icon={faStar}
                      />{" "}
                      0
                    </h4>
                    <div className="font-weight-600 indicator-title">
                      Reputation
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 text-center">
                    <button className="btn btn-dark  mr-2 btn-circle" onClick={() => {
                      this.props.history.push(`/request-call/${this.props.user.name}`);
                    }}>
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                    {this.props.user.name !== this.props.userActive.name ? <React.Fragment>
                      {this.props.userActive.subscriptions.indexOf(
                        this.props.user.id
                      ) === -1 ? (
                        <button
                          className="btn btn-primary btn-radius-50"
                          type={"button"}
                          onClick={() => {
                            this.props
                              .subscribe(
                                this.props.userActive.id,
                                this.props.user.id
                              )
                              .then((response) => {
                                this.props
                                  .userViewToken()
                                  .then((response) => {
                                    this.props.userNewData(response.data);
                                  });
                              });
                          }}
                        >
                          Follow
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-radius-50"
                          type={"button"}
                          onClick={() => {
                            this.props
                              .unsubscribe(
                                this.props.userActive.id,
                                this.props.user.id
                              )
                              .then((response) => {
                                this.props
                                  .userViewToken()
                                  .then((response) => {
                                    this.props.userNewData(response.data);
                                  });
                              });
                          }}
                        >
                          Unfollow
                        </button>
                      )}
                    </React.Fragment> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: state.settings,
    userActive: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userNewData: (data) => {
      dispatch(userNewData(data));
    },
    getFollows: (id) => {
      return dispatch(getFollows(id));
    },
    subscribe: (id_user1, id_user2) => {
      return dispatch(subscribe(id_user1, id_user2));
    },
    unsubscribe: (id_user1, id_user2) => {
      return dispatch(unsubscribe(id_user1, id_user2));
    },
    userViewToken: () => {
      return dispatch(userViewToken());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyProfileUserBlock));
